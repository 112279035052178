import { AnimatePresence, motion } from "framer-motion";
import { useCallback } from "react";
import { useForm } from "react-hook-form";
import { IoMdClose } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import STATES from "../constants/states";
import { updateSequenceNo } from "../services/sequenceNo";
import readUserId from "../services/user";

export default function SequenceNoModal({ modalOpen, handleToggleModal }) {
    const navigate = useNavigate();

    const { register, handleSubmit } = useForm({
        defaultValues: {
            state: "CA",
            currSequenceNo: 0,
        },
    });

    const handleUpdateSequenceNo = useCallback(async (data, e) => {
        e.preventDefault();

        const userId = await readUserId();
        await updateSequenceNo(userId, data);
        handleToggleModal();
        navigate(0);
    }, []);

    return (
        <AnimatePresence>
            {modalOpen && (
                <motion.div
                    key="overlay"
                    className="flex justify-center items-center fixed inset-0 z-50 bg-neutral-600/70"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    <motion.div
                        key="modal"
                        className="w-2/5 bg-white shadow-md rounded-xl overflow-y-auto h-1/4"
                        initial={{ opacity: 0, scale: 0.9 }}
                        animate={{ opacity: 1, scale: 1 }}
                        exit={{ opacity: 0, scale: 0.9 }}
                    >
                        <form onSubmit={handleSubmit(handleUpdateSequenceNo)}>
                            <section className="flex mt-6 ml-6 mr-4">
                                <h3 className="text-2xl font-semibold">
                                    Set a New Sequence No.
                                </h3>
                                <IoMdClose
                                    size={18}
                                    className="ml-auto cursor-pointer duration-500 hover:opacity-50"
                                    onClick={handleToggleModal}
                                />
                            </section>
                            <section className="flex justify-between mt-6 ml-6 mr-4">
                                <select
                                    {...register("state")}
                                    className="outline-none border-[1px] border-neutral-400 rounded-md px-2 [-webkit-appearance]:none"
                                >
                                    {STATES.map((state) => (
                                        <option key={state} value={state}>
                                            {state}
                                        </option>
                                    ))}
                                </select>
                                <input
                                    {...register("currSequenceNo")}
                                    className="border-neutral-400 border-[1px] rounded-md px-2 py-2 text-sm text-neutral-700 font-normal outline-none"
                                />
                            </section>
                            <section className="flex pr-4 pb-6 mt-6">
                                <button
                                    type="submit"
                                    className="ml-auto bg-blue-400 px-4 py-3 rounded-lg duration-500 hover:opacity-50 text-sm text-white font-semibold"
                                >
                                    Submit
                                </button>
                            </section>
                        </form>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}
