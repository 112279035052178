export async function readSettingsByUserId(userId) {
    const res = await fetch(
        `${process.env.REACT_APP_API_URL}/settings/${userId}`,
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        },
    );

    if (!res.ok) {
        throw new Error("Failed to read settings");
    }

    return res.json();
}

export async function updateSettings(userId, bRate, dRate, sRate, ssRate) {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/settings`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
            userId,
            bRate,
            dRate,
            sRate,
            ssRate,
        }),
    });

    if (!res.ok) {
        throw new Error("Failed to update settings");
    }

    return res.json();
}
