import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";

import AuthorizedRoute from "../components/AuthorizedRoute";
import Loading from "../components/Loading";
import Spinner from "../components/Spinner";
import Toast from "../components/Toast";
import sendMail from "../services/mail";
import { orderExists } from "../services/order";

export default function Confirm() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [submitting, setSubmitting] = useState(false);
    const [query, setQuery] = useState({
        loading: true,
        error: false,
    });

    const { register, handleSubmit } = useForm({
        defaultValues: {
            to: "",
        },
    });

    const onSubmit = useCallback(async (data, e) => {
        e.preventDefault();
        setSubmitting(true);

        try {
            await sendMail(data.to, searchParams.get("orderId"));

            toast.custom(({ visible }) => (
                <Toast
                    visible={visible}
                    message="Your email was sent successfully."
                    type="success"
                />
            ));
        } catch (err) {
            toast.custom(({ visible }) => (
                <Toast
                    visible={visible}
                    message="An error occurred while sending your email."
                    type="error"
                />
            ));
        } finally {
            setSubmitting(false);
        }
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const res = await orderExists();

                setQuery({
                    loading: false,
                    error: res === "false",
                });
            } catch (e) {
                setQuery({ loading: false, error: true });
            }
        })();
    }, []);

    if (query.loading) return <Loading />;
    if (query.error) navigate("/");

    return (
        <AuthorizedRoute>
            <main className="w-1/2 mx-auto py-16">
                <h1 className="font-bold text-3xl">Confirm</h1>
                <h3 className="text-sm text-neutral-400">
                    Your files have been processed and generated. You have
                    received a sample email showing what your customer will
                    receive. You <strong>must</strong> double-check the
                    generated files for accuracy. If everything looks good, fill
                    out the form below to email your customer.
                </h3>
                <form className="mt-8" onSubmit={handleSubmit(onSubmit)}>
                    <h2 className="text-xl font-semibold">Email Customers</h2>
                    <br />
                    <label className="flex flex-col gap-2" htmlFor="to">
                        <p className="text-sm text-neutral-600">Email To</p>
                        <input
                            {...register("to")}
                            className="border-[1px] border-neutral-400 rounded-md outline-none p-2 text-sm text-neutral-900"
                            type="text"
                            id="to"
                        />
                    </label>
                    <button
                        type="submit"
                        className="flex justify-center mt-8 w-full py-2 bg-blue-400 text-white font-medium outline-none"
                    >
                        {submitting ? <Spinner /> : "Submit"}
                    </button>
                </form>
                <Toaster position="bottom-center" />
            </main>
        </AuthorizedRoute>
    );
}
