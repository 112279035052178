import { useCallback, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import AuthorizedRoute from "../components/AuthorizedRoute";

import Dropzone from "../components/Dropzone";
import Spinner from "../components/Spinner";
import Toast from "../components/Toast";
import { createPivotTable } from "../services/order";
import readUserId from "../services/user";

export default function Upload() {
    const navigate = useNavigate();

    const [submitting, setSubmitting] = useState(false);
    const [orderFile, setOrderFile] = useState(null);
    const [warehouseFile, setWarehouseFile] = useState(null);

    const handleSubmit = useCallback(
        async (e) => {
            e.preventDefault();
            setSubmitting(true);

            toast.custom(({ visible }) => (
                <Toast
                    visible={visible}
                    message="We are processing your files. This may take some time."
                    type="info"
                />
            ));

            try {
                const userId = await readUserId();

                const orderId = await createPivotTable(
                    userId,
                    orderFile,
                    warehouseFile,
                );
                navigate(`/confirm?orderId=${orderId}`);
            } catch (err) {
                toast.custom(({ visible }) => (
                    <Toast
                        visible={visible}
                        message={err.message}
                        type="error"
                    />
                ));
            }
        },
        [orderFile, warehouseFile],
    );

    return (
        <AuthorizedRoute>
            <main className="w-1/2 mx-auto py-16">
                <h1 className="font-bold text-3xl">
                    Generate Pivot Tables & Email Customers
                </h1>
                <h3 className="text-sm text-neutral-400">
                    Upload order and warehouse sheets to auto-generate pivot
                    tables and email invoices to customers.
                </h3>
                <form className="mt-8" onSubmit={handleSubmit}>
                    <h2 className="text-xl font-semibold">
                        Upload Order Sheet
                    </h2>
                    <p className="text-sm text-neutral-400 font-medium">
                        Make sure that your file ends in .xlsx.
                    </p>
                    <Dropzone
                        disabled={submitting}
                        file={orderFile}
                        setFile={setOrderFile}
                    />
                    <h2 className="mt-8 text-xl font-semibold">
                        Upload Warehouse Information
                    </h2>
                    <p className="text-sm text-neutral-400 font-medium">
                        Make sure that your file ends in .xlsx.
                    </p>
                    <Dropzone
                        disabled={submitting}
                        file={warehouseFile}
                        setFile={setWarehouseFile}
                    />
                    <button
                        type="submit"
                        className="flex justify-center mt-8 w-full py-2 bg-blue-400 text-white font-medium outline-none"
                    >
                        {submitting ? <Spinner /> : "Submit"}
                    </button>
                </form>
                <Toaster position="bottom-center" />
            </main>
        </AuthorizedRoute>
    );
}
