import React from "react";
import { render } from "react-dom";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import "./index.css";
import Home from "./pages/home";
import Confirm from "./pages/confirm";
import Login from "./pages/login";
import Register from "./pages/register";
import Upload from "./pages/upload";
import reportWebVitals from "./reportWebVitals";
import Settings from "./pages/settings";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
    },
    {
        path: "/login",
        element: <Login />,
    },
    {
        path: "/register",
        element: <Register />,
    },
    {
        path: "/upload",
        element: <Upload />,
    },
    {
        path: "/confirm",
        element: <Confirm />,
    },
    {
        path: "/settings",
        element: <Settings />,
    },
]);

render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>,
    document.getElementById("root"),
);

reportWebVitals();
