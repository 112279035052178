import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Loading from "./Loading";
import { validateToken } from "../services/auth";

export default function UnauthorizedRoute({ children }) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                const isAuthorized = await validateToken();

                if (isAuthorized === "true") {
                    navigate("/settings");
                }
            } catch (e) {
                localStorage.removeItem("token");
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    if (loading) return <Loading />;
    return children;
}
