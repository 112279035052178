import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/Spinner";
import UnauthorizedRoute from "../components/UnauthorizedRoute";
import { register as registerUser } from "../services/auth";

export default function Register() {
    const navigate = useNavigate();
    const [submitting, setSubmitting] = useState(false);
    const { handleSubmit, register } = useForm({
        defaultValues: {
            email: "",
            password: "",
        },
    });

    const handleRegister = useCallback(async (data, e) => {
        e.preventDefault();

        setSubmitting(true);

        try {
            const token = await registerUser(data.email, data.password);
            localStorage.setItem("token", token);
            navigate("/settings");
        } finally {
            setSubmitting(false);
        }
    }, []);

    return (
        <UnauthorizedRoute>
            <main className="flex justify-center items-center h-screen">
                <section className="rounded-md w-1/5">
                    <h1 className="text-center font-medium text-2xl">
                        Register
                    </h1>
                    <form
                        onSubmit={handleSubmit(handleRegister)}
                        className="mt-4 flex flex-col gap-4"
                    >
                        <label
                            htmlFor="email"
                            className="flex flex-col gap-2 font-medium"
                        >
                            Email
                            <input
                                {...register("email")}
                                className="border-neutral-400 border-[1px] rounded-md px-2 py-2 text-sm text-neutral-700 font-normal outline-none"
                            />
                        </label>
                        <label
                            htmlFor="password"
                            className="flex flex-col gap-2 font-medium"
                        >
                            Password
                            <input
                                type="password"
                                {...register("password")}
                                className="border-neutral-400 border-[1px] rounded-md px-2 py-2 text-sm text-neutral-700 font-normal outline-none"
                            />
                        </label>
                        <button
                            type="submit"
                            className="mt-2 bg-blue-400 text-white font-medium py-2 rounded-md"
                        >
                            {submitting ? <Spinner /> : "Register"}
                        </button>
                    </form>
                </section>
            </main>
        </UnauthorizedRoute>
    );
}
