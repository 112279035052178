export default function SettingsRateView({ register }) {
    return (
        <section className="mt-12 w-full border-[1px] border-neutral-200 rounded-md px-12 py-8">
            <ul className="flex flex-col gap-2">
                <li className="flex items-center justify-between">
                    BOX DAMAGE
                    <input
                        {...register("bRate")}
                        className="border-[1px] border-neutral-400 rounded-md outline-none p-2 text-sm text-neutral-900"
                        type="text"
                    />
                </li>
                <li className="flex items-center justify-between">
                    DENT & SCRATCH
                    <input
                        {...register("dRate")}
                        className="border-[1px] border-neutral-400 rounded-md outline-none p-2 text-sm text-neutral-900"
                        type="text"
                    />
                </li>
                <li className="flex items-center justify-between">
                    AS-IS & SCRAP
                    <input
                        {...register("sRate")}
                        className="border-[1px] border-neutral-400 rounded-md outline-none p-2 text-sm text-neutral-900"
                        type="text"
                    />
                </li>
                <li className="flex items-center justify-between">
                    SCRAP & SCRAP
                    <input
                        {...register("ssRate")}
                        className="border-[1px] border-neutral-400 rounded-md outline-none p-2 text-sm text-neutral-900"
                        type="text"
                    />
                </li>
            </ul>
        </section>
    );
}
