export default async function readUserId() {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/user`, {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    });

    if (!res.ok) {
        throw new Error("Failed to read user");
    }

    return res.text();
}
