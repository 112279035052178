import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { AiOutlineClose, AiOutlineCloudDownload } from "react-icons/ai";
import { FaFile } from "react-icons/fa";

export default function Dropzone({ disabled, file, setFile }) {
    const onDrop = useCallback(
        (acceptedFiles) => setFile(acceptedFiles[0]),
        [setFile],
    );

    const { getRootProps, getInputProps } = useDropzone({
        accept: {
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                [".xlsx"],
        },
        maxFiles: 1,
        onDrop,
    });

    const handleDelete = useCallback(() => {
        if (!disabled) {
            setFile(null);
        }
    }, [setFile]);

    return (
        <section>
            <div
                {...getRootProps({ name: "dropzone" })}
                className="mt-6
               flex
               flex-col
               justify-center
               items-center
               w-full
               h-52
               border-[1px]
               border-dashed
               border-neutral-400
               rounded-lg
               text-neutral-500
               outline-none
               cursor-pointer
               duration-500
               hover:opacity-50"
            >
                <label
                    htmlFor="file"
                    className="flex flex-col items-center justify-center"
                >
                    <input
                        {...getInputProps({ id: "file", name: "file" })}
                        disabled={disabled}
                    />
                    <AiOutlineCloudDownload size={48} />
                    <p className="text-xs text-neutral-400">
                        Drag & drop a file here, or click to select a file.
                    </p>
                </label>
            </div>
            {file ? (
                <aside className="flex gap-2 mt-6">
                    <div className="flex flex-col items-center justify-center text-center gap-2 w-48 h-32 border-[1px] border-dashed ">
                        <FaFile size={24} />
                        <p className="text-xs w-36 overflow-wrap break-words">
                            {file.name}
                        </p>
                    </div>
                    <AiOutlineClose
                        size={12}
                        onClick={handleDelete}
                        className="cursor-pointer duration-500 hover:opacity-50"
                    />
                </aside>
            ) : null}
        </section>
    );
}
