export async function register(email, password) {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/register`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            email,
            password,
        }),
    });

    if (!res.ok) {
        throw new Error("Failed to register");
    }

    return res.text();
}

export async function login(email, password) {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/auth/login`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
        },
        body: JSON.stringify({
            email,
            password,
        }),
    });

    if (!res.ok) {
        throw new Error("Failed to login");
    }

    return res.text();
}

export async function validateToken() {
    const res = await fetch(
        `${process.env.REACT_APP_API_URL}/auth/validate-token`,
        {
            method: "POST",
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        },
    );

    if (!res.ok) {
        throw new Error("Failed to validate token");
    }

    return res.text();
}
