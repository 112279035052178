export default async function sendMail(to, orderId) {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/mail`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
            to,
            orderId,
        }),
    });

    if (!res.ok) {
        throw new Error("An error occurred while sending your email.");
    }

    return res.json();
}
