export async function orderExists(orderId) {
    const res = await fetch(
        `${process.env.REACT_APP_API_URL}/order/${orderId}`,
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        },
    );

    if (!res.ok) {
        throw new Error("Failed to check if order exists");
    }

    return res.text();
}

export async function createPivotTable(userId, orderFile, warehouseFile) {
    const formData = new FormData();
    formData.append("userId", userId);
    formData.append("orderFile", orderFile);
    formData.append("warehouseFile", warehouseFile);

    const res = await fetch(
        `${process.env.REACT_APP_API_URL}/order/pivot-table`,
        {
            method: "POST",
            body: formData,
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        },
    );

    if (!res.ok) {
        throw new Error("Failed to create pivot table");
    }

    return res.text();
}
