import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { validateToken } from "../services/auth";
import Loading from "./Loading";

export default function AuthorizedRoute({ children }) {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        (async () => {
            try {
                const isAuthorized = await validateToken();

                if (isAuthorized !== "true") {
                    localStorage.removeItem("token");
                    navigate("/");
                }
            } catch (e) {
                localStorage.removeItem("token");
                navigate("/");
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    if (loading) return <Loading />;
    return children;
}
