import { AnimatePresence, motion } from "framer-motion";
import { useMemo } from "react";

export default function Toast({ visible, message, type }) {
    const BG_COLOR = useMemo(() => {
        switch (type) {
            case "success":
                return "bg-green-400";
            case "info":
                return "bg-blue-400";
            default:
                return "bg-red-400";
        }
    }, [type]);

    return (
        <AnimatePresence>
            {visible && (
                <motion.div
                    initial={{ opacity: 0, scale: 0.9 }}
                    animate={{ opacity: 1, scale: 1 }}
                    exit={{ opacity: 0, scale: 0.9 }}
                    className={`
                  ${BG_COLOR}
                  flex
                  justify-center
                  shadow-lg
                  rounded-lg
                `}
                >
                    <div className="px-5 py-3 font-semibold text-sm text-white">
                        {message}
                    </div>
                </motion.div>
            )}
        </AnimatePresence>
    );
}
