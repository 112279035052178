import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CiCirclePlus } from "react-icons/ci";
import AuthorizedRoute from "../components/AuthorizedRoute";
import Loading from "../components/Loading";
import SequenceNoModal from "../components/SequenceNoModal";
import SettingsRateView from "../components/SettingsRateView";
import { readAllSequenceNosByUserId } from "../services/sequenceNo";
import { readSettingsByUserId, updateSettings } from "../services/settings";
import readUserId from "../services/user";

export default function Settings() {
    const [modalOpen, setModalOpen] = useState(false);
    const [settingsQuery, setSettingsQuery] = useState({
        loading: true,
        data: null,
        error: false,
    });
    const [sequenceNosQuery, setSequenceNosQuery] = useState({
        loading: true,
        data: [],
        error: false,
    });

    const { register, handleSubmit, setValue } = useForm({
        defaultValues: {
            bRate: 0.0,
            dRate: 0.0,
            sRate: 0.0,
            ssRate: 0.0,
        },
    });

    const handleUpdateSettings = useCallback(async (data, e) => {
        e.preventDefault();

        const userId = await readUserId();
        await updateSettings(
            userId,
            data.bRate,
            data.dRate,
            data.sRate,
            data.ssRate,
        );
    }, []);

    const toggleModal = useCallback(() => {
        setModalOpen(!modalOpen);
    }, [modalOpen, setModalOpen]);

    useEffect(() => {
        if (!settingsQuery.loading && settingsQuery.data) {
            setValue("bRate", settingsQuery.data.bRate);
            setValue("dRate", settingsQuery.data.dRate);
            setValue("sRate", settingsQuery.data.sRate);
            setValue("ssRate", settingsQuery.data.ssRate);
        }
    }, [settingsQuery.loading, settingsQuery.data]);

    useEffect(() => {
        (async () => {
            try {
                const userId = await readUserId();
                const res = await readSettingsByUserId(userId);
                setSettingsQuery({ loading: false, data: res, error: false });
            } catch (e) {
                setSettingsQuery({ loading: false, data: null, error: true });
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const userId = await readUserId();
                const res = await readAllSequenceNosByUserId(userId);
                setSequenceNosQuery({
                    loading: false,
                    data: res,
                    error: false,
                });
            } catch (e) {
                setSequenceNosQuery({
                    loading: false,
                    data: null,
                    error: true,
                });
            }
        })();
    }, []);

    if (settingsQuery.loading || sequenceNosQuery.loading) return <Loading />;

    return (
        <AuthorizedRoute>
            <main className="w-1/2 mx-auto py-16">
                <h1 className="font-bold text-3xl">Settings</h1>
                <h3 className="text-sm text-neutral-400">
                    Update the rates at which you wish to charge your customers
                    here. Your values must be a decimal in the format of 0.XX.
                </h3>
                <form onSubmit={handleSubmit(handleUpdateSettings)}>
                    <SettingsRateView register={register} />
                    <section className="mt-8 w-full border-[1px] border-neutral-200 rounded-md px-12 py-8">
                        <button
                            type="button"
                            aria-label="Add new sequence no"
                            className="flex ml-auto duration-300 hover:opacity-50 mb-4"
                            onClick={toggleModal}
                        >
                            <CiCirclePlus size={20} />
                        </button>
                        {sequenceNosQuery.data &&
                            sequenceNosQuery.data.map((sequenceNo) => (
                                <div className="flex items-center justify-between">
                                    <p className="font-semibold">
                                        {sequenceNo.state}
                                    </p>
                                    <p className="text-xs">
                                        {sequenceNo.currSequenceNo}
                                    </p>
                                </div>
                            ))}
                    </section>
                    <button
                        className="flex ml-auto mt-4 bg-blue-400 text-white font-medium py-2 px-4 rounded-md"
                        type="submit"
                    >
                        Update Settings
                    </button>
                </form>
                <SequenceNoModal
                    modalOpen={modalOpen}
                    handleToggleModal={toggleModal}
                />
            </main>
        </AuthorizedRoute>
    );
}
