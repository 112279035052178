export async function readAllSequenceNosByUserId(userId) {
    const res = await fetch(
        `${process.env.REACT_APP_API_URL}/sequence-nos/${userId}`,
        {
            headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        },
    );

    if (!res.ok) {
        throw new Error("Failed to read sequence numbers");
    }

    return res.json();
}

export async function updateSequenceNo(userId, data) {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/sequence-nos`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
            state: data.state,
            currSequenceNo: data.currSequenceNo,
            userId,
        }),
    });

    if (!res.ok) {
        throw new Error("Failed to update sequence numbers");
    }

    return res.json();
}
